*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.dialog-box {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 450px;
  height: 300px;
  align-items: center;
  border: none;
  border-radius: 40px;
  box-shadow: 0px 5px 32px 3px;
  transform-origin: center;
  transform: translate(50%, 50%);
}

.dialog-box::backdrop {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.235);
}

html::-webkit-scrollbar {
  display: none;
  width: 0;
}

::-webkit-scrollbar {
  display: none;
}

.mb10{
  margin-bottom:10px;
  width: 100%;
}
.mb20{
  margin-bottom: 20px;
  width: 100%;
}

.mt5{
  margin-top: 5px;
}
.mt20{
  margin-top: 20px;
}
.textCenter{
  text-align: center;
}

html {
  scrollbar-width: 0;
}

body {
  background: black;
}
.mx5{
  margin-inline: 5px;
}

:root {
  --hue: 223;
  --bg: hsl(var(--hue), 10%, 90%);
  --fg: hsl(var(--hue), 10%, 10%);
  --primary: hsl(var(--hue), 90%, 55%);
  --primary-l: hsl(var(--hue), 90%, 65%);
  --primary-d: hsl(var(--hue), 90%, 45%);
  --white: hsl(var(--hue), 10%, 100%);
  --white-d: hsl(var(--hue), 10%, 45%);
}

.d-flex{
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.column{
  flex-direction: column;
}
.g8{
  gap:8px;
}
img{
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.j-end{
  justify-content: end;
}
.j-between{
  justify-content: space-between;
}
.j-center{
  justify-content: center;
}
.j-evenly{
  justify-content: space-evenly;
}
.c-point{
  cursor: pointer;
}

.m-auto{
  margin: auto;
}

.badge {
  font-size: 0.85rem;
  padding: 3px 9px;
  border-radius: 19px;
  color: white;
  font-weight: 600;
  background: rgb(225, 0, 0);
  box-shadow: 2px 2px 5px 0px #b9a2a2;
  width: max-content !important;
  height: 100% !important;
}

.success{
  background: #009d4c !important;
}


/* ----------------- Loader ---------------------- */

.relative{
  position: relative;
}

.help-text{
  display: inline;
  font-size: 0.95rem;
}
.info {
  box-shadow: inset -2px -2px 7px 0 grey;
  border-radius: 16px;
  padding: 1px 10px;
}

 .card {
     background: #fff;
     border-radius: 10px;
   max-height: 430px;
   padding: 15px;
     width: 100%;
     overflow: hidden;
  }

.card.transactions{
  min-height: 430px;
  overflow: scroll;

}

.card-body {
  width: 100%;
  line-height: normal;
  height: 100%;
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 0 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  overflow: scroll;
}
.card-image{
  background-image: linear-gradient(rgba(34, 34, 34, 0.8), rgba(34, 34, 34, 0.8)), url('/src/assets/ivancik.jpg');
  background-size: cover;
  color: white;
}
.desc-small{
  font-weight: 600;
  font-size:1rem;
  
}
  .tag {
    width: 100%;
    text-transform: uppercase;
    padding: 8px 4px;
    border-radius: 4px;
    color: white;
    background: #614500;
    font-size: 15px;
    line-height: 20px;
}


.desc-small span{
  font-weight:400;
  font-size: 0.6rem;
}



 .icon {
     display: block;
     font-size: 35px;
     height: 100%;
     width: 45px;
   }


.icon span {
  margin: auto;
  vertical-align: middle;
}
/* ----------------- form STYLE ---------------------- */


/* ----------------- button STYLE ---------------------- */
.btn {
  cursor: pointer;
  background: #141a23;
  padding: 12px 25px;
  text-align: center;
  border: none;
  border-radius: 4px;
  font-size: 0.98rem;
  color: white;
  text-transform: uppercase;
  font-weight: 550;
  box-shadow: 3px 3px 10px 1px rgba(6, 6, 6, 0.761);
}
.reward-icon {
  /* margin-left: 50px; */
  /* max-width: 54%; */
  opacity: 0.6;
  z-index: 11;
  position: absolute;
}

.btn-trans {
  padding: 8px 20px;
  border-radius: 9px;
  background: rgb(255 255 255 / 76%);
  font-size: 0.9rem;
  color: #344767;
  font-weight: 600;
}

.btn:hover{
  zoom:0.98;
  box-shadow: 3px 3px 10px 5px rgba(6, 6, 6, 0.761);
}

.btn-prime{
  background: #1c3563;
}
.btn-reward{
  background: goldenrod;
  color: rgb(255, 255, 255);
  border-radius: 30px;
  padding: 8px 20px;
  text-transform: uppercase;
}
.btn-round{
  background: white;
  color: black;
  border-radius: 50%;
  padding: 15px !important;
}
.btn-disabled {
  background: #ffffff7d;
  box-shadow: inset 0px 0px 6px 0px black;
  color: black;
  border-radius: 30px;
  padding: 8px 20px;
  text-transform: uppercase;
  font-size: 0.99rem;
}

/* ----------------- INPUT STYLE ---------------------- */
input{
  width: 100%;
  border: 0.5px solid rgb(142, 136, 136);
  border-radius:4px;
  padding: 10px 10px;
  font-size: 0.98rem;
  outline: none;
}

input:focus{
  border:2px solid #713d6a;
}

input[type=checkbox], input[type=radio]{
width: 20px;
height: 20px;
background:#ccc;
border: none;
outline: none;
cursor: pointer;
  padding: 10px;
  vertical-align: middle;
}
input[type=checkbox]:checked{
  outline: none;
  border: none;
  background-color: #1976d2;
}
.custom-form-control {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.custom-label {
  color: #1976d2; /* Primary color */
  font-size: 0.98rem;
  align-self: center;
}

.custom-select-wrapper {
  width: 100%;
}

.custom-select {
  width: 100%;
  padding: 8px 10px;
  font-size: 0.98rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  appearance: none; /* Remove default arrow styling */
  background-color: white;
}

.custom-select:focus {
  outline: none;
  border-color: #1976d2; /* Primary color on focus */
}

.custom-form-control {
  display: flex;
  align-items: center;
  margin-bottom: 16px; /* Space between form controls */
}

.custom-label {
  margin-right: 16px;
  width: 50%; /* Label width */
}

.file-label {
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 2px dashed #007bff; /* Border style */
  border-radius: 5px;
  padding: 8px;
  background-color: #f8f9fa; /* Background color */
  transition: background-color 0.3s;
}

.file-label:hover {
  background-color: #e9ecef; /* Change background on hover */
}

.upload-icon {
  margin-right: 8px; /* Space between icon and text */
}

.file-name {
  font-size: 0.9rem; /* File name font size */
}

/* CUSTOM TABLE */
.custom-table {
  width: 100%;

  border-collapse: collapse;
}

.custom-table table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
}

.custom-table th,
.custom-table td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #ddd; /* Only bottom border */
  font-size: 16px;
  font-family: 'Arial', sans-serif;
}

.custom-table th {
  font-weight: 600;
  text-transform: uppercase; /* Classy uppercase styling for headers */
  border-bottom: 2px solid #333; /* Thicker bottom border for headers */
  letter-spacing: 0.05rem;
}

.right-text {
  text-align: right;
}

.center-text {
  text-align: center;
}

.custom-table tbody tr:hover {
  border-bottom: 2px solid #999; /* Slightly thicker border on hover for classy effect */
}

.custom-table td {
  color: #333;
  font-size: 15px;
}

.custom-table th:first-child, 
.custom-table td:first-child {
  padding-left: 0; /* Align first column closer to the edge for a clean look */
}

.custom-table th:last-child, 
.custom-table td:last-child {
  padding-right: 0; /* Align last column closer to the edge for balance */
}

/* PRODUCT CARD */
.card-container{
  width: 100%;
  overflow: scroll;
  height: 100%;
}
.custom-card {
  background-color: #152a3e;
  color: #fff;
  border-radius: 8px;
  white-space: nowrap;
  padding: 20px;
  width: 300px;
  font-family: Arial, sans-serif;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.card-content-horizontal {
  display: flex;
  align-items: center;
}

.progress-circle {
  position: relative;
  width: 60px;
  height: 60px;
}

.circular-progress {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  top:0;
  border: 5px solid rgba(255, 255, 255, 0.2);
  clip: rect(0, 60px, 60px, 30px);
  animation: progressAnimation 1s forwards infinite;
}

@keyframes progressAnimation {
  from { transform: rotate(0deg); }
  to { transform: rotate(calc(90 * 3.6deg)); }
}

.progress-icon {
  position: relative;
  z-index: 2;
  width: 40px;
  height: 40px;
  margin: 10px;
  color: white;
}

.card-content {
  margin-left: 20px;
}

.body-md {
  font-size: 1rem;
  margin-bottom: 5px;
}

.profit-amount {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0;
}

.card-actions {
  display: flex;
  justify-content: start;
  gap: 10px;
  margin-top: 20px;
}


/* PORTFOLIO CARD STYLE */
.br-card {
  position: relative; /* Ensure the pseudo-element is positioned relative to the .br-card */
  background: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  color: #333;
  padding: 15px;
  border-radius: 10px;
}

.span1, .span2, .span3{
  font-size: 1rem;
  color: white;
  font-weight: 800;
  padding: 3px 15px;
  margin: 3px 1px;
  border-radius: 20px 0 0 20px;
  white-space: nowrap;
  max-width: fit-content ;
}
.span1{
  background:#252323;
}
.span2{
  background:#172b4d;
}
.span3{
  background:#512b4c;
}
.card-head{
  font-size: 24px;
  white-space: nowrap;
  font-family:Georgia, 'Times New Roman', Times, serif;
}

.br-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/src/assets/images/curved-images/white-curved.jpeg") no-repeat center center;
  background-size: cover;
  opacity: 0.8; /* Adjust this value to control the opacity */
  z-index: 0; /* Ensure the background stays behind the content */
  border-radius: 10px; /* Match the border radius */
}
.card-icon-space {
  margin-right: 10px;
}
.card-icon-space img{
  height: 60px;
  width: 60px;
}
.face-card{
  position: relative;
  min-width: 32%;
}
.face-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("/src/assets/images/curved-images/curved14.jpg") no-repeat center center;
  background-size: cover;
  opacity: 0.4;
  color: white;
  z-index: 0;
  border-radius: 10px;
}
.content-card {
  display: flex;
  gap: 20px;
  padding: 0 5px;
  flex-wrap: wrap;
  overflow: scroll;

}


.card-group-content {
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  min-width: 85%;
}
.card .image {
  overflow: hidden;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.image img {
  width: 600px;
}

.card-badge {
  width: 100%;
  text-align: center;
  font-size: 0.88rem;
  background: #1f2633;
  text-transform: uppercase;
  padding: 2px 0;
  font-weight: 600;
  color: white;
}

.prod-cards {
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
}
.prod-cards .card {
  /* min-height: 100%; */
  max-width: 367px;
  white-space: nowrap;
  overflow: hidden;
}
.card-filler {
  line-height: 26px;
  font-size: 1rem;
  margin: 0 0 10px;
  gap: 8px;
  font-weight: 600;
  color: #875488;
  width: 100%;
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  flex-wrap: nowrap;
  text-align: center;
}

.card-filler p {
  width: 100%;
}

.price-tag {
  text-transform: uppercase;
  font-size: 1.1rem;
  padding: 6px;
  border-radius: 5px;
  color: #152a3e;
}

p.prod-unit {
  position: absolute;
  top: 20px;
  left: 15px;
  color: white;
  font-size: 1.6rem;
  font-weight: 600;
  background: #222936;
  padding: 30px 20px;
  border-radius: 65%;
  width: 100px;
  height: 100px;
  align-items: center;
  display: flex;
  justify-content: center;
}
/* @media screen and (max-width: 475px) {
  .card-group-content{
    flex-wrap: wrap
  }
  .face-card .content-card .card-group-content{
    justify-content: center;
  }
} */